<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备配置</el-breadcrumb-item>
      <el-breadcrumb-item>采集设备管理</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
      <div v-auth="'measurePointManagement-add'">
        <el-button type="primary" size="small" @click="addMeasurePoint"
          >新增采集设备</el-button
        >
        &nbsp;
      </div>
      <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
        v-loading="spinning"
        :data="data"
        row-key="id"
      >
        <el-table-column prop="customMeterName" label="监测点名字">
        </el-table-column>
        <el-table-column prop="customMeterAddress" label="监测点位置">
        </el-table-column>
        <el-table-column prop="organizationName" label="机构">
        </el-table-column>
        <el-table-column prop="farmAreaName" label="农场"> </el-table-column>
        <el-table-column
          prop="meterModel.meterName"
          width="220"
          label="关联仪表型号"
        >
          <template slot-scope="scope">
            <el-popover placement="bottom" width="600" trigger="click">
              <el-form label-position="left" class="demo-table-expand">
                <el-form-item label="仪表名字">
                  <span>{{ scope.row.meterModel.meterName }}</span>
                </el-form-item>
                <el-form-item label="支持电压">
                  <span>{{ scope.row.meterModel.meterSupportVoltage }}</span>
                </el-form-item>
                <el-form-item label="仪表类型">
                  <span>
                    {{
                      scope.row.meterModel.meterType === "collect"
                        ? "采集"
                        : scope.row.meterModel.meterType === "control"
                        ? "控制"
                        : "采集+控制"
                    }}
                  </span>
                </el-form-item>
                <el-form-item
                  label="采集项"
                  style="width: 100%"
                  v-if="scope.row.meterModel.meterCollectItemList"
                >
                  <el-tag
                    size="small"
                    effect="plain"
                    v-for="collect in scope.row.meterModel.meterCollectItemList"
                    :key="collect.id"
                    style="margin-left: 5px"
                  >
                    {{ collect.describe }}
                  </el-tag>
                </el-form-item>
                <el-form-item
                  label="监测项目"
                  style="width: 100%"
                  v-if="scope.row.meterModel.meterControlItemList"
                >
                  <el-tag
                    size="small"
                    type="danger"
                    effect="plain"
                    v-for="collect in scope.row.meterModel.meterControlItemList"
                    :key="collect.id"
                    style="margin-left: 5px"
                  >
                    {{ collect.describe }}
                  </el-tag>
                </el-form-item>
              </el-form>
              <a href="#" slot="reference">{{
                scope.row.meterModel.meterName
              }}</a>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="dtuDeviceId"
          min-width="190"
          label="关联网关设备id"
        >
          <template slot-scope="scope">
            <el-popover placement="bottom" width="600" trigger="click">
              <el-form label-position="left" class="demo-table-expand">
                <el-form-item label="dtu名字">
                  <span>{{ scope.row.dtuModelInstallInfo.customDtuName }}</span>
                </el-form-item>
                <el-form-item label="dtu位置">
                  <span>{{
                    scope.row.dtuModelInstallInfo.customDtuAddress
                  }}</span>
                </el-form-item>
                <el-form-item label="dtu设备id">
                  <span>{{ scope.row.dtuModelInstallInfo.dtuDeviceId }}</span>
                </el-form-item>
                <el-form-item label="dtu版本号">
                  <span>{{ scope.row.dtuModelInstallInfo.dtuVersion }}</span>
                </el-form-item>
                <el-form-item label="dtu采集频率">
                  <span>{{ scope.row.dtuModelInstallInfo.dtuBaudRate }}</span>
                </el-form-item>
                <el-form-item label="dtu采集周期">
                  <span>{{
                    scope.row.dtuModelInstallInfo.dtuCollectCycle
                  }}</span>
                </el-form-item>
                <el-form-item label="mqtt代理服务器地址">
                  <span>{{
                    scope.row.dtuModelInstallInfo.mqttServiceAddress
                  }}</span>
                </el-form-item>
                <el-form-item label="mqtt代理服务器用户名">
                  <span>{{
                    scope.row.dtuModelInstallInfo.mqttServerUsername
                  }}</span>
                </el-form-item>
                <el-form-item label="mqtt代理服务器密码">
                  <span>{{
                    scope.row.dtuModelInstallInfo.mqttServerPassword
                  }}</span>
                </el-form-item>
                <el-form-item label="mqtt代理服务器端口">
                  <span>{{
                    scope.row.dtuModelInstallInfo.mqttServerPort
                  }}</span>
                </el-form-item>
                <el-form-item label="mqtt心跳包开关状态">
                  <span>{{
                    scope.row.dtuModelInstallInfo.mqttHeartbeatEnabled
                  }}</span>
                </el-form-item>
                <el-form-item label="mqtt心跳包周期(单位:分钟)">
                  <span>{{
                    scope.row.dtuModelInstallInfo.mqttHearbeatCycle
                  }}</span>
                </el-form-item>
                <el-form-item label="mqtt最大心跳包数量">
                  <span>{{
                    scope.row.dtuModelInstallInfo.mqttHearbeatQuantity
                  }}</span>
                </el-form-item>
              </el-form>
              <a href="#" slot="reference">{{ scope.row.dtuDeviceId }}</a>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="informationId" label="信道"> </el-table-column>
        <el-table-column
          fixed="right"
          width="180"
          label="操作"
          align="center"
          v-if="
            authVerify.verify(
              'measurePointManagement-edit,measurePointManagement-delete'
            )
          "
        >
          <template slot-scope="scope">
            <el-button
              icon="el-icon-edit-outline"
              size="mini"
              v-auth="'measurePointManagement-edit'"
              @click="editMeasurePoint(scope.row)"
              >编辑
            </el-button>
            <el-button
              icon="el-icon-delete"
              size="mini"
              v-auth="'measurePointManagement-delete'"
              @click="deleteMeasurePoint(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
          title="新增监测点"
          width="600"
          placement="right"
          :closable="false"
          :visible="addMeasurePointVisible"
          @close="addMeasurePointVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="measurePointFormValue"
              ref="DTURefFormAdd"
              :rules="measurePointRules"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-form-model-item label="监测点名字" prop="customMeterName">
                <a-input v-model="measurePointFormValue.customMeterName" />
              </a-form-model-item>
              <a-form-model-item label="监测点位置" prop="customMeterAddress">
                <a-input v-model="measurePointFormValue.customMeterAddress" />
              </a-form-model-item>
              <a-form-model-item label="信道id" prop="informationId">
                <a-select v-model="measurePointFormValue.informationId">
                  <a-select-option v-for="num in 16" :key="num">
                    {{ num }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="关联dtu" prop="dtuModelInstallId">
                <a-select v-model="measurePointFormValue.dtuModelInstallId">
                  <a-select-option v-for="obj in dtuList" :key="obj.id">
                    {{ obj.customDtuName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="仪表型号" prop="meterModelId">
                <a-select v-model="measurePointFormValue.meterModelId">
                  <a-select-option v-for="obj in meterModelList" :key="obj.id">
                    {{ obj.meterName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="所属基地" prop="farmAreaId">
                <a-select v-model="measurePointFormValue.farmAreaId"  @change="farmAreaIdSelectChange">
                  <a-select-option v-for="obj in farmAreaList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="所属地块" prop="farmParcelId">
                <a-select v-model="measurePointFormValue.farmParcelId" >
                  <a-select-option v-for="obj in farmParcelList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addMeasurePointVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddMeasurePoint">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
          title="编辑监测点"
          width="600"
          placement="right"
          :closable="false"
          :visible="editMeasurePointVisible"
          @close="editMeasurePointVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="measurePointFormValue"
              ref="DTURefFormAdd"
              :rules="measurePointRules"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-form-model-item label="监测点名字" prop="customMeterName">
                <a-input v-model="measurePointFormValue.customMeterName" />
              </a-form-model-item>
              <a-form-model-item label="监测点位置" prop="customMeterAddress">
                <a-input v-model="measurePointFormValue.customMeterAddress" />
              </a-form-model-item>
              <a-form-model-item label="信道id" prop="informationId">
                <a-select v-model="measurePointFormValue.informationId">
                  <a-select-option v-for="num in 16" :key="num">
                    {{ num }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="关联dtu" prop="dtuModelInstallId">
                <a-select v-model="measurePointFormValue.dtuModelInstallId">
                  <a-select-option v-for="obj in dtuList" :key="obj.id">
                    {{ obj.customDtuName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="仪表型号" prop="meterModelId">
                <a-select v-model="measurePointFormValue.meterModelId">
                  <a-select-option v-for="obj in meterModelList" :key="obj.id">
                    {{ obj.meterName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="所属基地" prop="farmAreaId">
                <a-select v-model="measurePointFormValue.farmAreaId"  @change="farmAreaIdSelectChange">
                  <a-select-option v-for="obj in farmAreaList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="所属地块" prop="farmParcelId">
                <a-select v-model="measurePointFormValue.farmParcelId" >
                  <a-select-option v-for="obj in farmParcelList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editMeasurePointVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditMeasurePoint">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      measurePointFormValue: {
        id: "",
        customMeterName: "",
        customMeterAddress: "",
        informationId: undefined,
        dtuModelInstallId: undefined,
        meterModelId: undefined,
        farmAreaId: undefined,
          farmParcelId: undefined,
      },
      measurePointRules: {
        customMeterName: [
          { required: true, message: "请输入监测点名字!", trigger: "blur" },
        ],
        informationId: [
          { required: true, message: "请选择信道!", trigger: "blur" },
        ],
        dtuModelInstallId: [
          { required: true, message: "请选择关联的dtu!", trigger: "blur" },
        ],
        meterModelId: [
          { required: true, message: "请选择仪表型号!", trigger: "blur" },
        ],
        farmAreaId: [
          { required: true, message: "请选择基地!", trigger: "blur" },
        ],
      },
      spinning: true,
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50, //屏幕高度
      addMeasurePointVisible: false,
      editMeasurePointVisible: false,
      data: [],
      meterModelList: [],
      dtuList: [],
      farmAreaList: [],
        farmParcelList: [],
    };
  },
  watch: {
    screenWidth: function (val) {
      //监听屏幕宽度变化
      console.log(val);
    },
    screenHeight: function (val) {
      //监听屏幕高度变化
      console.log(val);
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },
  created() {
    this.getMeasurePointData();
    this.getMeterModelList();
    this.getDTUList();
    this.getFarmAreaList();
  },
  filters: {},
  computed: {
    pageHeight: function () {
      let height = document.documentElement.clientHeight;
      return height - 50;
    },
  },
  methods: {
      async farmAreaIdSelectChange() {
          this.measurePointFormValue.farmParcelId = '';
          this.farmParcelList = [];
          if(this.measurePointFormValue.farmAreaId != null && this.measurePointFormValue.farmAreaId != ''){
              const {data: res} = await this.http.farmParcel.getFarmParcelGroupByFarmAreaId({
                  id: this.measurePointFormValue.farmAreaId
              });
              if (res.status !== 200) {
                  return this.$message.error("获取农场地块失败");
              }else{
                  this.farmParcelList = res.data;
              }
          }
      },
    refreshFarmArea() {
      this.measurePointFormValue.farmAreaId = "";
    },
    getFarmAreaList() {
      this.http.farmArea.getFarmAreaList().then((response) => {
        if (response.data.status == 200) {
          this.farmAreaList = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    getMeterModelList() {
      this.http.device.getMeterModelList().then((res) => {
        if (res.data.status === 200) {
          this.meterModelList = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    getDTUList() {
      this.http.device.getDtuModelInstallInfoList().then((res) => {
        if (res.data.status === 200) {
          this.dtuList = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },

    getMeasurePointData() {
      this.http.device.getMeasurePointList().then((response) => {
        if (response.data.status == 200) {
          this.spinning = false;
          this.data = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    deleteMeasurePoint(key) {
      console.log(key);
      let self = this;
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.device
            .deleteMeasurePoint({
              id: key.id,
            })
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                self.getMeasurePointData();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },

    addMeasurePoint() {
      this.addMeasurePointVisible = true;
      this.cleanMeasurePoint();
        this.$refs.DTURefFormAdd.resetFields()
    },
    toAddMeasurePoint() {
      this.$refs.DTURefFormAdd.validate((valid) => {
        if (valid) {
          this.http.device
            .createMeasurePoint(this.measurePointFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.addMeasurePointVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getMeasurePointData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editMeasurePoint(record) {
      console.log(record);
      this.cleanMeasurePoint();
      this.measurePointFormValue = {
        id: record.id,
        customMeterName: record.customMeterName,
        customMeterAddress: record.customMeterAddress,
        informationId: record.informationId,
        dtuModelInstallId: record.dtuModelInstallId,
        meterModelId: record.meterModelId,
        farmAreaId: record.farmAreaId,
          farmParcelId: record.farmParcelId,
      };
        if(record.farmParcelId == null || record.farmParcelId == ''){
            this.farmAreaIdSelectChange();
        }else{
            this.http.farmParcel.getFarmParcelGroupByFarmAreaId({
                id: record.farmAreaId
            }).then(response => {
                if (response.data.status === 200) {
                    this.farmParcelList = response.data.data;
                } else {
                    return this.$message.error("获取农场地块失败");
                }
            });
        }
      this.editMeasurePointVisible = true;
        this.$refs.DTURefFormEdit.resetFields()
    },
    cleanMeasurePoint() {
      this.measurePointFormValue = {
        id: "",
        customMeterName: "",
        customMeterAddress: "",
          informationId: undefined,
          dtuModelInstallId: undefined,
          meterModelId: undefined,
          farmAreaId: undefined,
          farmParcelId: undefined,
      };
    },

    toEditMeasurePoint() {
      this.$refs.DTURefFormEdit.validate((valid) => {
        if (valid) {
          this.http.device
            .updateMeasurePoint(this.measurePointFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.editMeasurePointVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getMeasurePointData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}

.demo-table-expand >>> .el-form-item__label {
  color: #99a9bf;
  font-size: 8px;
}

.demo-table-expand >>> .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
